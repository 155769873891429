import dynamic from 'next/dynamic';
import { renderContentfulEntry } from '@bscom/utilities/renderers';

const DynamicModules = {
  pageHeadline: dynamic(() =>
    import(
      /* webpackChunkName: 'Headline' */
      '@bscom/content-blocks/Headline'
    )
  ),
  pageRelatedPages: dynamic(() =>
    import(
      /* webpackChunkName: 'PageRelatedPages' */
      'components/RelatedPages'
    )
  ),
  twoColSquareContent: dynamic(() =>
    import(
      /* webpackChunkName: 'TwoColumnSquareContent' */
      'components/TwoColumnSquareContent'
    )
  ),
};

export const renderContentBlock = renderContentfulEntry(DynamicModules);
