import React from 'react';
import { useTranslations } from 'ilenia';
import styled from 'styled-components';

import { DARK_GREEN } from 'src/constants';
import NavigationBasic from '@bscom/components/Navigation/NavigationBasic';
import {
  LinkWrapper,
  ButtonWrapper,
} from '@bscom/components/Navigation/NavigationLinks';

const StyledNavBasic = styled(NavigationBasic)`
  background: ${DARK_GREEN};
  div[class*='NavigationBasic__HeaderLinks'] {
    background: ${DARK_GREEN};
  }
`;

const NavigationLegal = () => {
  const [translations, localeCode] = useTranslations();

  return (
    <StyledNavBasic localeCode={localeCode}>
      <LinkWrapper
        translations={translations['header/legal/reviewers']}
        identifier="for-reviewers"
        href="/for-reviewers/guidelines-for-reviewers"
      />
      <LinkWrapper
        translations={translations['header/legal/businesses']}
        identifier="for-businesses"
        href="/for-businesses/guidelines-for-businesses"
      />
      <LinkWrapper
        translations={translations['header/legal/everyone']}
        identifier="for-everyone"
        href="/for-everyone/code-of-ethics"
      />
      <ButtonWrapper
        translations={translations['header/legal/contact']}
        identifier="contact"
        href="/contact"
      />
    </StyledNavBasic>
  );
};

export default NavigationLegal;
