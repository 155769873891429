import { GenericPage, fetchPageData } from 'components/Page';

const PageHome = ({ page }) => {
  console.log(page);
  return <GenericPage page={page} />;
};

export const getServerSideProps = async (ctx) => {
  const props = await fetchPageData({ slug: 'home', ctx });
  return props;
};
export default PageHome;
