import getConfig from 'next/config';
const contentful = require('contentful');

import { restMapper } from '@bscom/utilities/restMapper';

const {
  publicRuntimeConfig: { contentful: contentfulConfig }
} = getConfig();

export const cfulClient = contentful.createClient({
  resolveLinks: true,
  removeUnresolved: true,
  ...contentfulConfig
});

export const fetchData = async ({ contentType, ...queries }) => {
  let result = await cfulClient.getEntries({
    content_type: contentType,
    include: 10,
    ...queries
  });

  return restMapper(result);
};
