import { Fragment } from 'react';
import { useTranslations } from 'ilenia';

import { getLocaleByHost } from '@bscom/locales';
import { slugRegex } from '@bscom/utilities/regex';
import { HeadMetaTags } from '@bscom/components/HeadMetaTags';

import { pageMeta } from 'src/metaMappers';
import { BaseLayout } from 'layouts/BaseLayout';
import { renderContentBlock } from 'src/block-renderers';
import { fetchData } from 'src/cfulClient';

const RawGenericPage = ({ page }) => {
  const [translations] = useTranslations();

  return (
    <BaseLayout>
      <HeadMetaTags {...pageMeta(page, translations)} />
      {page?.contentCollection?.items.map((content, index) => (
        <Fragment key={index}>{renderContentBlock(content)}</Fragment>
      ))}
    </BaseLayout>
  );
};

export const fetchPageData = async ({ slug, ctx }) => {
  const locale = getLocaleByHost(
    typeof window === 'undefined' ? ctx.req.headers.host : window.location.href
  );

  if (!slugRegex.test(slug)) {
    return { notFound: true };
  }

  const restData = await fetchData({
    contentType: 'page',
    'fields.slug[in]': slug,
    locale: locale.code,
    'fields.context': 'legal-site',
  });

  if (!restData.itemsCollection || !restData.itemsCollection.items[0]) {
    return { notFound: true };
  }

  const page = restData.itemsCollection.items[0];

  return { props: { page } };
};

export const GenericPage = RawGenericPage;
