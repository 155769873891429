import React from 'react';
import styled from 'styled-components';
import { useTranslations } from 'ilenia';
import Layout from '@trustpilot/consumersite-appshell/src/components/layout';

import { DARK_GREEN } from 'src/constants';
import NavigationLegal from 'components/NavigationLegal';

const PageWrapper = styled.div`
  position: relative;

  footer {
    background: ${DARK_GREEN};
  }

  footer * {
    font-size: 14px !important;
    font-weight: 500;
    letter-spacing: 0.5px;
    h3 {
      font-size: 18px !important;
    }
  }

  [data-navigation] * {
    font-size: 14px !important;
  }

  [class*='content'][data-navigation] * {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  & > div > div {
    display: none;
  }
  & > div > main {
    margin-top: 0 !important;
  }
`;

export const BaseLayout = ({ children }) => {
  const [, localeCode] = useTranslations();

  return (
    <PageWrapper>
      <NavigationLegal />
      <Layout
        fixedHeader
        locale={localeCode}
        hideSearch={true}
        hideOldCookieBar
        baseTrackingProperties={{
          contextName: `businesssite-legal`,
        }}
        pageName="Legal"
        localeCode={localeCode}
        hideHeader={true}
        footerProps={{
          countryLocaleLinkSelector: (locale) => locale.legalUrl,
          showHowTrustpilotWorksLink: true,
        }}
      >
        {children}
      </Layout>
    </PageWrapper>
  );
};
